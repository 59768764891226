@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@import './static/fonts/styles.css';
@layer base {
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    @apply inline align-baseline;
  }
  * {
    @apply border-gray-1100;
  }
  button {
    @apply outline-transparent;
  }
  :focus-visible {
    @apply outline-none;
  }
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }
}

@layer components {
  .filter-button {
    @apply flex items-center gap-1.5 rounded-xl border border-transparent bg-gray-650 p-3 text-xs font-bold radix-state-open:border-blue-1100 hover:bg-gray-1000 hover:!shadow-none disabled:opacity-50;
  }
  .radix-dropdown-content {
    @apply z-50 rounded-lg border-[0.5px] bg-white shadow-md will-change-[transform,opacity] radix-state-open:animate-slide-down;
  }
}

.tooltip {
  border-radius: 0.5rem !important;
  background-color: #101828 !important;
  padding: 8px 12px !important;
  z-index: 100;
  font-size: 0.85rem !important;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}
body,
.root {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: block;
  padding: 0;
  box-sizing: border-box;
}
#root {
  width: 100%;
  height: 100%;
  display: block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.scrollbar,
.scrollbar-small {
  scrollbar-width: thin;
}
.scrollbar::-webkit-scrollbar {
  height: 100%;
  width: 5px;
}
.scrollbar-small::-webkit-scrollbar {
  height: 3px;
  width: 5px;
}

.scrollbar::-webkit-scrollbar-thumb,
.scrollbar-small::-webkit-scrollbar-thumb {
  background: #d7d4d2;
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
#hubspot-messages-iframe-container.widget-align-right {
  pointer-events: all !important;
}
@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.remove-arrow {
  -moz-appearance: textfield;
}
